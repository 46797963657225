import { default as _400KQu5lyI94wMeta } from "/codebuild/output/src3295850636/src/melembre-landing-page-v2/pages/400.vue?macro=true";
import { default as _50090IRBHGVUnMeta } from "/codebuild/output/src3295850636/src/melembre-landing-page-v2/pages/500.vue?macro=true";
import { default as contatoBRykVsQJzzMeta } from "/codebuild/output/src3295850636/src/melembre-landing-page-v2/pages/contato.vue?macro=true";
import { default as casas_45bahiaiL53pO5cHhMeta } from "/codebuild/output/src3295850636/src/melembre-landing-page-v2/pages/credor/casas-bahia.vue?macro=true";
import { default as digioUpLeQEnRF7Meta } from "/codebuild/output/src3295850636/src/melembre-landing-page-v2/pages/credor/digio.vue?macro=true";
import { default as pontofrio3AgyTkr0ldMeta } from "/codebuild/output/src3295850636/src/melembre-landing-page-v2/pages/credor/pontofrio.vue?macro=true";
import { default as empresaPuUUuCqLTLMeta } from "/codebuild/output/src3295850636/src/melembre-landing-page-v2/pages/empresa.vue?macro=true";
import { default as index703HyKs6eoMeta } from "/codebuild/output/src3295850636/src/melembre-landing-page-v2/pages/index.vue?macro=true";
import { default as info22IBXCQJWtMeta } from "/codebuild/output/src3295850636/src/melembre-landing-page-v2/pages/info.vue?macro=true";
export default [
  {
    name: "400",
    path: "/400",
    component: () => import("/codebuild/output/src3295850636/src/melembre-landing-page-v2/pages/400.vue").then(m => m.default || m)
  },
  {
    name: "500",
    path: "/500",
    component: () => import("/codebuild/output/src3295850636/src/melembre-landing-page-v2/pages/500.vue").then(m => m.default || m)
  },
  {
    name: "contato",
    path: "/contato",
    component: () => import("/codebuild/output/src3295850636/src/melembre-landing-page-v2/pages/contato.vue").then(m => m.default || m)
  },
  {
    name: "credor-casas-bahia",
    path: "/credor/casas-bahia",
    meta: casas_45bahiaiL53pO5cHhMeta || {},
    component: () => import("/codebuild/output/src3295850636/src/melembre-landing-page-v2/pages/credor/casas-bahia.vue").then(m => m.default || m)
  },
  {
    name: "credor-digio",
    path: "/credor/digio",
    meta: digioUpLeQEnRF7Meta || {},
    component: () => import("/codebuild/output/src3295850636/src/melembre-landing-page-v2/pages/credor/digio.vue").then(m => m.default || m)
  },
  {
    name: "credor-pontofrio",
    path: "/credor/pontofrio",
    meta: pontofrio3AgyTkr0ldMeta || {},
    component: () => import("/codebuild/output/src3295850636/src/melembre-landing-page-v2/pages/credor/pontofrio.vue").then(m => m.default || m)
  },
  {
    name: "empresa",
    path: "/empresa",
    component: () => import("/codebuild/output/src3295850636/src/melembre-landing-page-v2/pages/empresa.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index703HyKs6eoMeta || {},
    component: () => import("/codebuild/output/src3295850636/src/melembre-landing-page-v2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/codebuild/output/src3295850636/src/melembre-landing-page-v2/pages/info.vue").then(m => m.default || m)
  }
]